var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _vm.$vuetify.breakpoint.mdAndUp
        ? _c(
            "v-row",
            { staticClass: "pt-2 pl-8", attrs: { "no-gutters": "" } },
            [
              _c(
                "span",
                {
                  staticClass:
                    "font-weight-medium grey--text text--darken-4 text-h6",
                },
                [_vm._v(_vm._s(_vm.$t("excavationDetails")))]
              ),
            ]
          )
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-row",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-lg",
                  staticStyle: {
                    border: "1px solid #e0e0e0",
                    "border-radius": "8%",
                  },
                  attrs: { width: "100%", elevation: "0" },
                },
                [
                  _c("MapView"),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-row",
                        { staticClass: "px-2", attrs: { justify: "center" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$store.state.Ticket.ticketData
                                    ? _vm.streetAddress
                                    : ""
                                )
                              ),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "primary--text my-2 body-2",
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("editLocation")
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/edit_location.svg"),
                                  role: "img",
                                  alt: "edit location Logo",
                                },
                              }),
                              _c("span", { staticClass: "mb-auto" }, [
                                _vm._v(_vm._s(_vm.$t("editLocation"))),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              class: _vm.$store.state.Ticket.ticketData.county
                                ? ""
                                : "my-auto",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("county")) + " : ")]
                          ),
                          _vm.$store.state.Ticket.ticketData.county &&
                          _vm.$store.state.Ticket.ticketData.county != ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.Ticket.ticketData
                                          ? _vm.$store.state.Ticket.ticketData
                                              .county
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "hide-details": "",
                                      placeholder: "Enter County",
                                    },
                                    model: {
                                      value: _vm.county,
                                      callback: function ($$v) {
                                        _vm.county = $$v
                                      },
                                      expression: "county",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              class: _vm.$store.state.Ticket.ticketData.place
                                ? ""
                                : "my-auto",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("place")) + " : ")]
                          ),
                          _vm.$store.state.Ticket.ticketData.place &&
                          _vm.$store.state.Ticket.ticketData.place != ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.Ticket.ticketData
                                          ? _vm.$store.state.Ticket.ticketData
                                              .place
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "hide-details": "",
                                      placeholder: "Enter Place",
                                    },
                                    model: {
                                      value: _vm.place,
                                      callback: function ($$v) {
                                        _vm.place = $$v
                                      },
                                      expression: "place",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              class: _vm.$store.state.Ticket.ticketData.street
                                ? ""
                                : "my-auto",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("street")) + " : ")]
                          ),
                          _vm.$store.state.Ticket.ticketData.street &&
                          _vm.$store.state.Ticket.ticketData.street != ""
                            ? _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$store.state.Ticket.ticketData
                                          ? _vm.streetAddress
                                          : ""
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "hide-details": "",
                                      placeholder: "Enter Street",
                                    },
                                    model: {
                                      value: _vm.street,
                                      callback: function ($$v) {
                                        _vm.street = $$v
                                      },
                                      expression: "street",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              class: _vm.streetLocation.cross1 ? "" : "my-auto",
                              attrs: { cols: "3" },
                            },
                            [
                              _c("span", { staticClass: "red--text" }, [
                                _vm._v(" *"),
                              ]),
                              _vm._v(" " + _vm._s(_vm.$t("cross1")) + " : "),
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "Enter Cross 1",
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  "error-messages": _vm.cross1Error,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.cross1.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.cross1.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.cross1,
                                  callback: function ($$v) {
                                    _vm.cross1 = $$v
                                  },
                                  expression: "cross1",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-1",
                              class: _vm.streetLocation.cross2 ? "" : "my-auto",
                              attrs: { cols: "3" },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cross2")) + " : ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  "hide-details": "",
                                  placeholder: "Enter Cross 2",
                                },
                                model: {
                                  value: _vm.cross2,
                                  callback: function ($$v) {
                                    _vm.cross2 = $$v
                                  },
                                  expression: "cross2",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "py-1", attrs: { cols: "3" } },
                            [_vm._v(" " + _vm._s(_vm.$t("location")) + " : ")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                            },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  rows: "3",
                                  counter: "1000",
                                  placeholder: "Enter Location",
                                  outlined: "",
                                  dense: "",
                                  required: "",
                                  "error-messages": _vm.locationError,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.$v.description.$touch()
                                  },
                                  blur: function ($event) {
                                    return _vm.$v.description.$touch()
                                  },
                                },
                                model: {
                                  value: _vm.description,
                                  callback: function ($$v) {
                                    _vm.description = $$v
                                  },
                                  expression: "description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8 pt-2" : "mt-1 mx-5",
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-weight-medium grey--text text--darken-4 text-subtitle-1",
                    },
                    [_vm._v(_vm._s(_vm.$t("811Center")))]
                  ),
                ]
              ),
              _c(
                "v-row",
                {
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pt-5 pb-2" : "py-3",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c("v-col", [
                    _c("img", {
                      staticClass: "py-4",
                      attrs: {
                        src: require("@/assets/images/NC811.svg"),
                        role: "img",
                        alt: "NC 811 Center Logo",
                      },
                    }),
                  ]),
                  _c(
                    "v-col",
                    {
                      class: _vm.$vuetify.breakpoint.mdAndUp
                        ? "mt-5"
                        : "mt-6 ml-10",
                    },
                    [
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-normal grey--text text--darken-1 text-caption",
                          },
                          [_vm._v(_vm._s(_vm.$t("excavatorType")))]
                        ),
                      ]),
                      _c("v-row", { attrs: { "no-gutters": "" } }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                          },
                          [_vm._v(_vm._s(_vm.companyExcavatorType))]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.$vuetify.breakpoint.mdAndUp
                ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-4 mb-10" },
                    [
                      _vm.checkPermission("associateTicketExcavationDetails")
                        ? _c("AssociateTicketExcvDetails", {
                            ref: "associateTicket",
                          })
                        : _vm._e(),
                      _c(
                        "v-row",
                        { staticClass: "mb-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-1",
                            },
                            [_vm._v(_vm._s(_vm.$t("tellAboutExcavation")))]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "", justify: "center" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu1",
                              attrs: {
                                "close-on-content-click": false,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "auto",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                label: "Excavation Start Date",
                                                "persistent-hint": "",
                                                outlined: "",
                                                dense: "",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                                "error-messages":
                                                  _vm.dateFormattedErrors,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.$v.work_date.$touch()
                                                },
                                                blur: function ($event) {
                                                  return _vm.$v.work_date.$touch()
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "red--text",
                                                          },
                                                          [_vm._v(" *")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "excavationStartDate"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.work_date,
                                                callback: function ($$v) {
                                                  _vm.work_date = $$v
                                                },
                                                expression: "work_date",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu1,
                                callback: function ($$v) {
                                  _vm.menu1 = $$v
                                },
                                expression: "menu1",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  min: _vm.getNowDate,
                                  max: _vm.getEndDate,
                                },
                                on: {
                                  input: function ($event) {
                                    _vm.menu1 = false
                                  },
                                },
                                model: {
                                  value: _vm.date,
                                  callback: function ($$v) {
                                    _vm.date = $$v
                                  },
                                  expression: "date",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                "return-value": _vm.time,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.time = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.time = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              class: _vm.$vuetify.breakpoint
                                                .mdAndUp
                                                ? "ml-3"
                                                : "",
                                              attrs: {
                                                label: _vm.$t("time"),
                                                format: "ampm",
                                                "prepend-inner-icon":
                                                  "mdi-clock-time-four-outline",
                                                "persistent-hint": "",
                                                outlined: "",
                                                dense: "",
                                                "error-messages":
                                                  _vm.scheduleTimeError,
                                                readonly: "",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.$v.scheduleTime.$touch()
                                                },
                                                blur: function ($event) {
                                                  return _vm.$v.scheduleTime.$touch()
                                                },
                                              },
                                              model: {
                                                value: _vm.scheduleTime,
                                                callback: function ($$v) {
                                                  _vm.scheduleTime = $$v
                                                },
                                                expression: "scheduleTime",
                                              },
                                            },
                                            "v-text-field",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu2,
                                callback: function ($$v) {
                                  _vm.menu2 = $$v
                                },
                                expression: "menu2",
                              },
                            },
                            [
                              _vm.menu2
                                ? _c(
                                    "v-time-picker",
                                    {
                                      attrs: {
                                        "menu-props": {
                                          bottom: true,
                                          offsetY: true,
                                        },
                                        "full-width": "",
                                      },
                                      model: {
                                        value: _vm.time,
                                        callback: function ($$v) {
                                          _vm.time = $$v
                                        },
                                        expression: "time",
                                      },
                                    },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.menu2 = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { text: "", color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$refs.menu.save(
                                                _vm.time
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" OK ")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Site Contact Name",
                              outlined: "",
                              dense: "",
                              required: "",
                              "error-messages": _vm.contactErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.contact.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.contact.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("siteContactName")) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.contact,
                              callback: function ($$v) {
                                _vm.contact = $$v
                              },
                              expression: "contact",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Site Contact Phone",
                              outlined: "",
                              dense: "",
                              required: "",
                              "error-messages": _vm.contactPhoneErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.contact_phone.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.contact_phone.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("siteContactPhone")) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.contact_phone,
                              callback: function ($$v) {
                                _vm.contact_phone = $$v
                              },
                              expression: "contact_phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Sub-Division",
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.subdivisionErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.subdivision.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.subdivision.$touch()
                              },
                            },
                            model: {
                              value: _vm.subdivision,
                              callback: function ($$v) {
                                _vm.subdivision = $$v
                              },
                              expression: "subdivision",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Building Number",
                              outlined: "",
                              dense: "",
                              "error-messages": _vm.lotErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.lots.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.lots.$touch()
                              },
                            },
                            model: {
                              value: _vm.lots,
                              callback: function ($$v) {
                                _vm.lots = $$v
                              },
                              expression: "lots",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Gate Code",
                              outlined: "",
                              dense: "",
                              required: "",
                              "error-messages": _vm.gateCodeErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.gate_code.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.gate_code.$touch()
                              },
                            },
                            model: {
                              value: _vm.gate_code,
                              callback: function ($$v) {
                                _vm.gate_code = $$v
                              },
                              expression: "gate_code",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("excavationAreaOnProperty")))]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                right: !_vm.$vuetify.breakpoint.smAndDown,
                                bottom: _vm.$vuetify.breakpoint.smAndDown,
                                "max-width": "400",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("excavationAreaOnPropertyToolTip")
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-select", {
                            class:
                              _vm.location && _vm.$vuetify.breakpoint.smAndDown
                                ? "select-container"
                                : "",
                            attrs: {
                              items: _vm.selectExcavationAreaOnProperty,
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Excavation Area on Property",
                              outlined: "",
                              dense: "",
                              required: "",
                              "item-value": "text",
                              "error-messages": _vm.excavationAreaErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.location.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.location.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("excavationAreaOnProperty")
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.location,
                              callback: function ($$v) {
                                _vm.location = $$v
                              },
                              expression: "location",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("typeOfWork")))]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                right: !_vm.$vuetify.breakpoint.smAndDown,
                                bottom: _vm.$vuetify.breakpoint.smAndDown,
                                "max-width": "400",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("typeOfWorkToolTip"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.typeOfWorkTextBox
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Enter Type of Work",
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "hide-details":
                                        _vm.typeOFWorkErrors.length == 0,
                                      "error-messages": _vm.typeOFWorkErrors,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.work_type.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.work_type.$touch()
                                      },
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v(" *")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("typeOfWork")) +
                                                  " "
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2793586954
                                    ),
                                    model: {
                                      value: _vm.work_type,
                                      callback: function ($$v) {
                                        _vm.work_type = $$v
                                      },
                                      expression: "work_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", { attrs: { "no-gutters": "" } }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "primary--text my-2 body-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.disableTypeOfWorkTextBox()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("searchFromList")))]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.workTypeOptions,
                                      "menu-props": {
                                        bottom: true,
                                        offsetY: true,
                                      },
                                      label: "Type of Work",
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "item-value": "text",
                                      "error-messages": _vm.typeOFWorkErrors,
                                      "hide-details":
                                        _vm.typeOFWorkErrors.length == 0,
                                    },
                                    on: {
                                      change: _vm.emptyWorkType,
                                      input: function ($event) {
                                        return _vm.$v.work_type.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.work_type.$touch()
                                      },
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              { staticClass: "red--text" },
                                              [_vm._v(" *")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("typeOfWork")) +
                                                " "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                    model: {
                                      value: _vm.work_type,
                                      callback: function ($$v) {
                                        _vm.work_type = $$v
                                      },
                                      expression: "work_type",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", { attrs: { "no-gutters": "" } }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "primary--text my-2 body-2",
                                    on: {
                                      click: function ($event) {
                                        return _vm.enableTypeOfWorkTextBox()
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("dontSeeTypeOfWork")))]
                                ),
                              ]),
                            ],
                            1
                          ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Work Duration",
                              outlined: "",
                              dense: "",
                              required: "",
                              "error-messages": _vm.durationErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.duration.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.duration.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(" Work Duration "),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.duration,
                              callback: function ($$v) {
                                _vm.duration = $$v
                              },
                              expression: "duration",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Who is the work being done for?",
                              outlined: "",
                              dense: "",
                              required: "",
                              "error-messages": _vm.workDoneForErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.done_for.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.done_for.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("whoIsTheWork")) + " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.done_for,
                              callback: function ($$v) {
                                _vm.done_for = $$v
                              },
                              expression: "done_for",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("locatorInstructions")) + " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-normal grey--text text--darken-1 text-caption",
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("checkAllApply")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value: "Be aware of pet/animal in yard/fence",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("BeAwareLabel")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value: "Call prior to locating",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("callPriorLabel")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-normal grey--text text--darken-1 text-caption",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("ifPossibleLabel")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value:
                                    "Call prior to locating to obtain gate code",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "callPriorGateCodeLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value: "Mark with flags only",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("markWithFlagsLabel")
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-normal grey--text text--darken-1 text-caption",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("ifPossibleLabel")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value: "Mark with paint and flags",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "markWithPaintAndFlagsLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-normal grey--text text--darken-1 text-caption",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("ifPossibleLabel")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-10",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  "hide-details": "",
                                  value:
                                    "Please don’t mark hard surfaces, such as driveway/sidewalks/hardscape",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "markHardSurfacesLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "font-weight-normal grey--text text--darken-1 text-caption",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("ifPossibleLabel")
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.locatorInstructions,
                                  callback: function ($$v) {
                                    _vm.locatorInstructions = $$v
                                  },
                                  expression: "locatorInstructions",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("directionsToAddress")))]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                right: !_vm.$vuetify.breakpoint.smAndDown,
                                bottom: _vm.$vuetify.breakpoint.smAndDown,
                                "max-width": "400",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("directionsToAddressToolTip"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Directions to Address",
                              "error-messages": _vm.directionToAddressErrors,
                              rows: "2",
                              counter: "120",
                              outlined: "",
                              dense: "",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.directionToAddress.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.directionToAddress.$touch()
                              },
                            },
                            model: {
                              value: _vm.directionToAddress,
                              callback: function ($$v) {
                                _vm.directionToAddress = $$v
                              },
                              expression: "directionToAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("additionalComments")))]
                          ),
                          _c(
                            "v-tooltip",
                            {
                              staticClass: "pa-0",
                              attrs: {
                                right: !_vm.$vuetify.breakpoint.smAndDown,
                                bottom: _vm.$vuetify.breakpoint.smAndDown,
                                "max-width": "400",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-4",
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("additionalCommentsToolTip"))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Comments",
                              "error-messages": _vm.remarksErrors,
                              rows: "2",
                              counter: "120",
                              outlined: "",
                              dense: "",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.remarks.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.remarks.$touch()
                              },
                            },
                            model: {
                              value: _vm.remarks,
                              callback: function ($$v) {
                                _vm.remarks = $$v
                              },
                              expression: "remarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mt-4", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("additionalInformation")) + " "
                              ),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-weight-normal grey--text text--darken-1 text-caption",
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("checkAllApply")))]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: { "hide-details": "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("explosivesLabel")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.blasting,
                                  callback: function ($$v) {
                                    _vm.blasting = $$v
                                  },
                                  expression: "blasting",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-checkbox", {
                                attrs: { "hide-details": "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "workRailroadTrackLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.rr,
                                  callback: function ($$v) {
                                    _vm.rr = $$v
                                  },
                                  expression: "rr",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-16",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c("v-checkbox", {
                                attrs: { "hide-details": "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "directionalBoringLabel"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.boring,
                                  callback: function ($$v) {
                                    _vm.boring = $$v
                                  },
                                  expression: "boring",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-4 mb-2",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "font-weight-medium grey--text text--darken-4 text-subtitle-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("premarksLabel")))]
                          ),
                        ]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "mb-12", attrs: { "no-gutters": "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.premarksOptions,
                              "menu-props": { bottom: true, offsetY: true },
                              label: "Premarked With",
                              outlined: "",
                              dense: "",
                              required: "",
                              "item-value": "text",
                              "error-messages": _vm.premarksErrors,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.premarks.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.premarks.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", { staticClass: "red--text" }, [
                                      _vm._v(" *"),
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("premarkedWith")) +
                                        " "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.premarks,
                              callback: function ($$v) {
                                _vm.premarks = $$v
                              },
                              expression: "premarks",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.$vuetify.breakpoint.mdAndUp
                        ? _c(
                            "v-row",
                            {
                              staticClass: "mb-16",
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-none rounded-lg elevation-0",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    width: "90",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backButton()
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "ml-6 primary text-none rounded-lg elevation-0",
                                  attrs: {
                                    width: "90",
                                    depressed: "",
                                    disabled: _vm.$v.$invalid,
                                  },
                                  on: { click: _vm.continueButton },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("continue")) + " ")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "rounded-lg mx-4",
                      staticStyle: {
                        border: "1px solid #e0e0e0",
                        "border-radius": "8%",
                      },
                      attrs: { width: "94%", elevation: "0" },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { staticClass: "pt-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-2 my-2",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$store.state.Ticket.ticketData
                                          ? _vm.streetAddress
                                          : ""
                                      )
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "v-col",
                                { staticClass: "d-flex justify-end pt-2" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "primary--text my-2 body-2",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("editLocation")
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/edit_location.svg"),
                                          role: "img",
                                          alt: "edit location Logo",
                                        },
                                      }),
                                      _c("span", { staticClass: "mb-auto" }, [
                                        _vm._v(_vm._s(_vm.$t("editLocation"))),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class:
                                    _vm.$store.state.Ticket.ticketData.county &&
                                    _vm.$store.state.Ticket.ticketData.county !=
                                      ""
                                      ? ""
                                      : "my-auto",
                                  attrs: { cols: "2" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("county")) + " : ")]
                              ),
                              _vm.$store.state.Ticket.ticketData.county &&
                              _vm.$store.state.Ticket.ticketData.county != ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$store.state.Ticket.ticketData
                                              ? _vm.$store.state.Ticket
                                                  .ticketData.county
                                              : "—"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          required: "",
                                          "hide-details": "",
                                          placeholder: "Enter County",
                                        },
                                        model: {
                                          value: _vm.county,
                                          callback: function ($$v) {
                                            _vm.county = $$v
                                          },
                                          expression: "county",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class:
                                    _vm.$store.state.Ticket.ticketData.place &&
                                    _vm.$store.state.Ticket.ticketData.place !=
                                      ""
                                      ? ""
                                      : "my-auto",
                                  attrs: { cols: "2" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("place")) + " : ")]
                              ),
                              _vm.$store.state.Ticket.ticketData.place &&
                              _vm.$store.state.Ticket.ticketData.place != ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$store.state.Ticket.ticketData
                                              ? _vm.$store.state.Ticket
                                                  .ticketData.place
                                              : "—"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          required: "",
                                          "hide-details": "",
                                          placeholder: "Enter Place",
                                        },
                                        model: {
                                          value: _vm.place,
                                          callback: function ($$v) {
                                            _vm.place = $$v
                                          },
                                          expression: "place",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class:
                                    _vm.$store.state.Ticket.ticketData.street &&
                                    _vm.$store.state.Ticket.ticketData.street !=
                                      ""
                                      ? ""
                                      : "my-auto",
                                  attrs: { cols: "2" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("street")) + " : ")]
                              ),
                              _vm.$store.state.Ticket.ticketData.street &&
                              _vm.$store.state.Ticket.ticketData.street != ""
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$store.state.Ticket.ticketData
                                              ? _vm.streetAddress
                                              : "—"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          outlined: "",
                                          dense: "",
                                          required: "",
                                          "hide-details": "",
                                          placeholder: "Enter Street",
                                        },
                                        model: {
                                          value: _vm.street,
                                          callback: function ($$v) {
                                            _vm.street = $$v
                                          },
                                          expression: "street",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class: _vm.streetLocation.cross1
                                    ? ""
                                    : "my-auto",
                                  attrs: { cols: "2", required: "" },
                                },
                                [
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v(" *"),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("cross1")) + " : "
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Enter Cross 1",
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "error-messages": _vm.cross1Error,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.cross1.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.cross1.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.cross1,
                                      callback: function ($$v) {
                                        _vm.cross1 = $$v
                                      },
                                      expression: "cross1",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class: _vm.streetLocation.cross2
                                    ? ""
                                    : "my-auto",
                                  attrs: { cols: "2" },
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("cross2")) + " : ")]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "hide-details": "",
                                      placeholder: "Enter Cross 2",
                                    },
                                    model: {
                                      value: _vm.cross2,
                                      callback: function ($$v) {
                                        _vm.cross2 = $$v
                                      },
                                      expression: "cross2",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "py-1",
                                  class: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "pl-2 pr-0"
                                    : "",
                                  attrs: { cols: "2" },
                                },
                                [
                                  _c("span", { staticClass: "red--text" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("Location"),
                                  _c(
                                    "v-tooltip",
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        right:
                                          !_vm.$vuetify.breakpoint.smAndDown,
                                        bottom:
                                          _vm.$vuetify.breakpoint.smAndDown,
                                        "max-width": "400",
                                        location: "bottom",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          dark: "",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      " mdi-information-outline "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2517027790
                                      ),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Provide a detailed description of your work area"
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1",
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      rows: "3",
                                      counter: "1000",
                                      placeholder: "Enter Location",
                                      outlined: "",
                                      dense: "",
                                      required: "",
                                      "error-messages": _vm.locationError,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.description.$touch()
                                      },
                                      blur: function ($event) {
                                        return _vm.$v.description.$touch()
                                      },
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("MapView", {
                        attrs: { "is-edit-view": _vm.isEditView },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$vuetify.breakpoint.smAndDown
            ? _c(
                "v-bottom-navigation",
                {
                  staticClass: "px-2 py-4",
                  attrs: { fixed: "", horizontal: "", height: "70" },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "space-between" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "1" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "rounded-lg",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                height: "50",
                              },
                              on: { click: _vm.backButton },
                            },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-arrow-left"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "9" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-none rounded-lg font-weight-regular text-h6 white--text",
                              attrs: {
                                color: "primary",
                                height: "50",
                                block: "",
                                disabled: _vm.$v.$invalid,
                              },
                              on: { click: _vm.continueButton },
                            },
                            [
                              _c("span", { staticClass: "white--text" }, [
                                _vm._v(_vm._s(_vm.$t("continue"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }